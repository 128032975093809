import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { mmsApi } from "./redux/services/mms";
import { workflowsApi } from "./redux/services/workflows";
import { linksApi } from "./redux/services/links";

// Reducer Imports
import applicationReducer from "@redux/slices/applicationSlice";
import {
  locatorAPI,
  vectorMapsAPI,
  applicationSlice,
} from "@livingmap/frontend-locator";

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    locatorApplication: applicationSlice,
    [mmsApi.reducerPath]: mmsApi.reducer,
    [workflowsApi.reducerPath]: workflowsApi.reducer,
    [linksApi.reducerPath]: linksApi.reducer,
    [locatorAPI.reducerPath]: locatorAPI.reducer,
    [vectorMapsAPI.reducerPath]: vectorMapsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      mmsApi.middleware,
      workflowsApi.middleware,
      linksApi.middleware,
      locatorAPI.middleware,
      vectorMapsAPI.middleware,
    ]),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
