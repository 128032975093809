import { useEffect, useState } from "react";
import { t } from "i18next";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed } from "keycloak-js";
import { Dialog } from "@livingmap/core-ui-v2";
import GitInfo from "react-git-info/macro";
import packageJSON from "../package.json";

import { useAppSelector } from "@redux/hooks";
import { useGetMapsQuery } from "@redux/services/mms";

import { useSetInitialProjectData } from "@hooks";

import { getProjectNames, isLivingMapUser } from "@utils";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ExpandingSidebar, {
  MenuItem,
} from "@components/ExpandingSidebar/ExpandingSidebar";
import { FeaturesView, WorkflowsView, ShortLinksView } from "./screens";
import ListView from "./screens/FeaturesView/screens/ListView/ListView";
import MapView from "./screens/FeaturesView/screens/MapView/MapView";
import LocatorView from "./screens/LocatorView/LocatorView";
import UnsavedChanges from "./screens/FeaturesView/Modal/UnsavedChanges";

import styles from "./App.module.css";

interface ParsedToken extends KeycloakTokenParsed {
  upn: string;
}

const { commit, branch } = GitInfo();

const BaseComponent = () => {
  const { keycloak } = useKeycloak();
  const { hasUnsavedChanges } = useAppSelector((state) => state.application);

  const [isUnsavedChangesModalActive, setUnsavedChangesModalActive] =
    useState(false);

  const { data } = useGetMapsQuery();

  const projectNames = getProjectNames(data);

  useSetInitialProjectData(projectNames);

  const token = keycloak.tokenParsed as ParsedToken;

  const handleConfirmUnsavedChanges = () => {
    keycloak.logout({ redirectUri: process.env.REACT_APP_BASE_URL });
  };

  const handleSignout = () => {
    if (hasUnsavedChanges) return setUnsavedChangesModalActive(true);

    keycloak.logout({ redirectUri: process.env.REACT_APP_BASE_URL });
  };

  // This useEffect is used to log the version, branch and commit hash of the app on load.
  useEffect(() => {
    console.log(
      `Version: ${packageJSON.version}\nBranch: ${branch}\nCommit hash: ${commit.hash}`,
    );
  }, []);

  // TODO: move menu items back into the sidebar component when feature flag is removed
  const menuItems: MenuItem[] = [
    {
      dataQA: "features-navlink",
      icon: {
        type: "MapIcon",
        width: 20,
        height: 20,
      },
      path: "/features",
      label: t("sidebar.menu_items.map"),
    },
    {
      dataQA: "workflows-navlink",
      icon: {
        type: "PlayCircleIcon",
        width: 20,
        height: 20,
      },
      path: "/workflows",
      label: t("sidebar.menu_items.workflows"),
    },
    {
      dataQA: "links-navlink",
      icon: {
        type: "QrIcon",
        width: 20,
        height: 20,
      },
      path: "/links",
      label: t("sidebar.menu_items.links"),
    },
  ];

  // TODO: remove feature flag for Locator
  if (isLivingMapUser()) {
    menuItems.push({
      dataQA: "locator-navlink",
      icon: {
        type: "LocationIcon",
        width: 20,
        height: 20,
      },
      path: "/locator",
      label: t("sidebar.menu_items.locator"),
    });
  }

  return (
    <div className={styles.base}>
      <ExpandingSidebar
        dataQA="menu-sidebar"
        menuItems={menuItems}
        username={token.upn}
        company={t("sidebar.company")}
        handleSignout={handleSignout}
      />
      <Outlet />
      <Dialog
        dataQA="unsaved-changes-modal"
        isOpen={isUnsavedChangesModalActive}
        onClose={() => setUnsavedChangesModalActive(false)}
        maxWidth={448}
      >
        <UnsavedChanges
          onCancel={() => setUnsavedChangesModalActive(false)}
          onConfirm={handleConfirmUnsavedChanges}
        />
      </Dialog>
    </div>
  );
};

function App() {
  return (
    <div className={styles.container}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to="/features/list-view" />}
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BaseComponent />
            </ProtectedRoute>
          }
        >
          <Route
            path="/features"
            element={<Navigate replace to="/features/list-view" />}
          />
          <Route path="/features/*" element={<FeaturesView />}>
            <Route path="list-view" element={<ListView />} />
            <Route path="map-view" element={<MapView />} />
          </Route>
          <Route path="/workflows" element={<WorkflowsView />} />
          <Route path="/links" element={<ShortLinksView />} />
          <Route path="/locator" element={<LocatorView />} />
        </Route>
      </Routes>
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
